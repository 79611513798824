.logoWrapper {
  max-width: 270px;
}

.title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  text-align: center;
  display: block;
  padding-bottom: 20px;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .flex1,
  .flex2 {
    display: flex;
    gap: 24px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1100px) {
  .title {
    font-size: 10px;
    max-width: 250px;
  }
}

@media (max-width: 1010px) {
  .logoWrapper {
    background-color: #fff;
    max-width: unset;
    padding: 24px 15px 0 15px;
    margin: 15px -16px;
  }
  .flexWrapper {
    .flex1,
    .flex2 {
      gap: 29px;
    }
  }

  .title {
    font-size: 16px;
    max-width: unset;
  }
}
