.body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

.viewTypeSwitcher {
  position: absolute;
  top: 65px;
  right: 20px;
}

.viewTeamSwitcher {
  position: absolute;
  top: 150px;
  right: 20px;
}

.Logos {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.SvgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
}

.MobileView {
  display: none;
}

.BiasEnvironment {
  position: absolute;
  top: 9%;
  left: 25px;
  padding: 8px 24px 14px 16px;

  .Label {
    font-weight: 600;
  }

  .Title,
  .Label {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }

  .Title {
    display: flex;
    align-items: center;
  }

  .Title:last-child {
    align-items: flex-start;
  }

  .ToolExplained {
    text-decoration: none;
    position: relative;
    z-index: 4;
  }

  .Separator {
    border: 1px solid #d9d9d9;
    width: 145px;
    margin-left: 0;
  }

  .Purple,
  .Pink,
  .Green,
  .Blue,
  .imgWrapper {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 16px;
  }

  .Purple {
    background-color: #cdc8fc;
  }

  .Pink {
    background-color: #dbcad1;
  }

  .Green {
    background-color: #c5eadd;
  }

  .Blue {
    background-color: #bbe4f2;
  }
}

.svg {
  width: 100%;
  max-height: calc(100vh - 48px);
}

/* lines */
.svg g[name='section 1'] path[type='line'] {
  stroke: #4c8cc1;
}

.svg g[name='section 2'] path[type='line'],
.svg g[name='section 3'] path[type='line'] {
  stroke: #75b3b3;
}

.svg g[name='section 4'] path[type='line'] {
  stroke: #80739b;
}

.svg g[name='section 5'] path[type='line'] {
  stroke: #8073ff;
}

/* labels */
.svg g[name='section 1'] path[type='label'] {
  fill: #bbe4f2;
  stroke-width: 2;
  stroke: transparent;
}

.svg g[name='section 2'] path[type='label'],
.svg g[name='section 3'] path[type='label'] {
  fill: #c5eadd;
  stroke-width: 2;
  stroke: transparent;
}

.svg g[name='section 4'] path[type='label'] {
  fill: #dbcad1;
  stroke-width: 2;
  stroke: transparent;
}

.svg g[name='section 5'] path[type='label'] {
  fill: #cdc8fc;
  stroke-width: 2;
  stroke: transparent;
}

.svg g[type='group'] {
  cursor: pointer;
}

.svg g[type='group'] text {
  fill: #333333;
  font-size: 10px;
  pointer-events: none;
}

.svg g[type='group'] image {
  pointer-events: none;
}

.svg text[type='title'] {
  fill: #28587b;
  font-family: 'RedHatDisplay';
  font-size: 36px;
}

.svg text[type='shortname'] {
  fill: #9e9e9e;
  font-family: 'RedHatDisplay';
  font-size: 18px;
}

.svg text[type='description'] {
  fill: #000;
  font-size: 16px;
}

.svg text[type='explanation-link'] {
  fill: #28587b;
  font-size: 16px;
  text-decoration: underline;
}

.svg text[type='legend'] {
  font-size: 16px;
  fill: #000;
}

.svg text[type='section-title'] {
  font-size: 24px;
  opacity: 0.65;
}

.svg text[type='section-title']:nth-of-type(1) {
  fill: #9fc8d3;
}

.svg text[type='section-title']:nth-of-type(2),
.svg text[type='section-title']:nth-of-type(3) {
  fill: #95c9b5;
}

.svg text[type='section-title']:nth-of-type(4) {
  fill: #c9a9b8;
}

.svg text[type='section-title']:nth-of-type(5) {
  fill: #acaae5;
}

@media (max-width: 1359px) {
  .viewTeamSwitcher {
    top: 160px;
    align-items: flex-end;
  }
  .SvgWrapper {
    .BiasEnvironment {
      left: 0;

      .Label,
      .Title {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .SvgWrapper {
    .BiasEnvironment {
      .Label,
      .Title {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 1010px) {
  .body {
    overflow: auto;
  }

  .SvgWrapper {
    display: none;
  }
  .MobileView {
    display: block;
    position: relative;
  }
}
